/* eslint-disable no-console */
import * as request from 'superagent';

export type AppConfigProps = {
  authconfig: {
    loginurl: string;
    homeurl: string;
    clientid: string;
    refreshtokeninterval: number;
    cookiedomain: string;
  };
  endpoints: {
    resilienceapi: string;
    csadmin: string;
    help: string;
    mapstyle: string;
  };
  tokens: {
    mapboxapi: string;
    tilesauth: string;
    intercom: {
      appid: string;
    };
    datadog: {
      applicationid: string;
    };
    amplitude: string;
  };
  features: {
    // if enablemock, frontend mocks are (either fully or partially) used for API calls.
    // if enablemock is false, all API calls go to resilienceapi endpoint.
    enablemock?: boolean;
    // if enablemock is true, allowpartialmocks lets you specify that certain API calls
    // you specify in Client.ts should be redirected to the actual resilienceapi endpoint.
    // Frontend mocks are used for all other API calls. If enablemock is false, this setting
    // is ignored
    allowpartialmocks?: boolean;
    enabledebug?: boolean;
    // Adjust the size of the mock location dataset used in the frontend serverMock.ts, up to ~7500
    nummocklocations?: number;
    enableamplitude?: boolean;
    buildingmatchui?: boolean;
  };
  datadog: {
    site?: string;
    service?: string;
    samplerate?: number;
    trackinteractions?: boolean;
    env?: string;
    version?: string;
  };
};

export class App {
  private static cfg: AppConfigProps = {
    authconfig: {
      loginurl: '',
      homeurl: '',
      clientid: '',
      refreshtokeninterval: 0,
      cookiedomain: '',
    },
    endpoints: {
      resilienceapi: `${window.location.protocol}//${window.location.host}`,
      csadmin: `${window.location.protocol}//${window.location.host}`,
      help: '',
      mapstyle: '',
    },
    tokens: {
      mapboxapi: '',
      tilesauth: '',
      intercom: {
        appid: '',
      },
      datadog: {
        applicationid: '',
      },
      amplitude: '',
    },
    features: {
      enablemock: false,
      allowpartialmocks: false,
      enabledebug: false,
      nummocklocations: 10000,
      enableamplitude: true,
      buildingmatchui: false,
    },
    datadog: {
      site: '',
      service: '',
      samplerate: 0,
      trackinteractions: false,
      env: '',
      version: '',
    },
  };

  static get config(): AppConfigProps {
    return App.cfg;
  }

  static set config(newConfig: AppConfigProps) {
    App.cfg = newConfig;
  }

  static debug(...args: unknown[]): void {
    if (this.config.features.enabledebug) {
      console.log(...args);
    }
  }

  static warn(...args: unknown[]): void {
    if (this.config.features.enabledebug) {
      console.warn(...args);
    }
  }

  static error(...args: unknown[]): void {
    if (this.config.features.enabledebug) {
      console.error(...args);
    }
  }

  static assert(condition: boolean, ...args: unknown[]): void {
    if (this.config.features.enabledebug) {
      console.assert(condition, ...args);
    }
  }

  static group(...args: unknown[]): void {
    if (this.config.features.enabledebug) {
      console.group(...args);
    }
  }

  static groupCollapsed(...args: unknown[]): void {
    if (this.config.features.enabledebug) {
      console.groupCollapsed(...args);
    }
  }

  static groupEnd(): void {
    if (this.config.features.enabledebug) {
      console.groupEnd();
    }
  }

  public static async getConfig(): Promise<AppConfigProps> {
    const response = await request
      .get(`${window.location.protocol}//${window.location.host}/config.json`)
      .accept('application/json')
      .then((res: request.Response) => {
        return res.body as AppConfigProps;
      })
      .catch((err: Error) => {
        if (process.env.REACT_APP_ENV === 'local') {
          return {
            authconfig: {
              clientid: 'gatekeeper',
              homeurl: 'https://applocal.dev.onec.cloud:4455',
              loginurl: 'https://applocal.dev.onec.cloud:4455/oauth/authorize',
              refreshtokeninterval: 1800,
              cookiedomain: 'applocal.dev.onec.cloud',
            },
            datadog: {
              env: 'helm-local',
              samplerate: 100,
              service: 'CS Admin',
              site: 'datadoghq.com',
              trackinteractions: true,
              version: 'master',
            },
            endpoints: {
              csadmin: 'https://applocal.dev.onec.cloud:4455',
              resilienceapi: 'https://applocal.dev.onec.cloud:4455',
            },
            features: {
              enabledebug: true,
              enablemock: false,
            },
            tokens: {
              datadog: {
                applicationid: '5abf2c9b-c518-450e-9329-1b2f425ae3aa',
              },
              googleanalytics: 'GTM-5LKNQTW',
              gtm: {
                auth: '__VsI03OCg9srNeARq0HNg',
                gtmid: 'GTM-5LKNQTW',
                preview: 'env-7',
              },
              mapboxapi: 'pk.eyJ1Ijoibmljb2xlaHUiLCJhIjoiSUFHaHEyVSJ9.sQh47fHih8dvNgItm92dqg',
            },
          } as unknown as AppConfigProps;
          // return a
        }
        console.error('error getting config', err);
        // Return defaults on error
        return App.config;
      });
    return response;
  }
}
